import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import { AccountUpdate } from "encharge-domain/lib/definitions/api/AccountUpdate";
import { AccountInfoResponse } from "store/accountStore";
import redirect from "domain/helpers/redirect";
import { getNodeEnv } from "domain/helpers/getNodeEnv";

export const getAccount = async () => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/accounts`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const getAccountAdmin = async (accountId: number) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/json`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    if (e.response.status === 401 || e.response.status === 403) {
      redirect("/");
    }
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const getAccountAdminMetrics = async (
  accountId: number,
  loadMetric: { load30Days: boolean; load5Years: boolean }
) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/metrics?load30Days=${
        loadMetric.load30Days || ""
      }&load5Years=${loadMetric.load5Years || ""}`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    if (e.response.status === 401 || e.response.status === 403) {
      redirect("/");
    }
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const actionByAdmin = async (actionUrl: string) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: actionUrl,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const disconnectServiceForAccount = async ({
  accountId,
  serviceName,
  removeFields,
}: {
  accountId: number;
  serviceName: string;
  removeFields: boolean;
}) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/services/${serviceName}`,
      params: {
        ...(removeFields ? { removeFields: true } : {}),
      },
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const addAdminNote = async (
  accountId: number,
  teamMemberId: number,
  text: string
) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/notes`,
      method: "POST",
      data: {
        accountId,
        teamMemberId,
        text,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const addTransactionalEmail = async (accountId: number) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/transactional-email`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const removeTransactionalEmail = async (accountId: number) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/transactional-email`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const startPauseEmail = async (accountId: number) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/pause-email`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const stopPauseEmail = async (accountId: number) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/pause-email`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const addActivityStream = async (accountId: number, url: string) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/activity-stream`,
      method: "POST",
      data: {
        accountId,
        url,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const deleteActivityStream = async (accountId: number) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/activity-stream?accountId=${accountId}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const deleteAdminNote = async (noteId: number) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/notes/${noteId}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const changeAccountOwner = async ({
  accountId,
  newOwnerId,
}: {
  accountId: number;
  newOwnerId: number;
}) => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/owners`,
      method: "POST",
      data: { ownerId: newOwnerId },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    return true;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't change the owner. ";
    throw new Error(msg);
  }
};

export const saveServiceCredentials = async (
  serviceId: string,
  credentials: any
) => {
  try {
    const res: AxiosResponse<{ status: string }> = await request({
      url: `${apiBase}/v1/auth/${serviceId}`,
      method: "POST",
      data: credentials,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save your credentials. ";
    throw new Error(msg);
  }
};

export const disconnectService = async (serviceId: string) => {
  try {
    const res: AxiosResponse<{ status: string }> = await request({
      url: `${apiBase}/v1/services/${serviceId}/disconnect`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    throw new Error("We couldn't disconnect your service. ");
  }
};

export const updateAccount = async (accountData: AccountUpdate) => {
  try {
    await request({
      url: `${apiBase}/v1/accounts/me`,
      method: "PATCH",
      data: accountData,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save your account changes. ";
    throw new Error(msg);
  }
};

export const switchAccount = async (accountId: IAccount["id"]) => {
  try {
    await request({
      url: `${apiBase}/v1/accounts/${accountId}/switch`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't switch accounts. ";
    throw new Error(msg);
  }
};
export const deleteAccount = async (accountId: IAccount["id"]) => {
  try {
    const res: AxiosResponse<{ result: string }> = await request({
      url: `${apiBase}/v1/accounts/${accountId}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.result;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't switch accounts. ";
    throw new Error(msg);
  }
};

export const uploadLogo = async (logo: File) => {
  try {
    const formData = new FormData();
    formData.append("file", logo);

    const res: AxiosResponse<{ url: string; key: string }> = await request({
      url: `${apiBase}/v1/accounts/logo`,
      method: "POST",
      data: {
        contentType: logo.type,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    const presignedS3Url = res.data.url;
    if (!presignedS3Url) {
      throw new Error("Couldn't get logo upload URL.");
    }

    const axiosResponse = await request({
      url: presignedS3Url.replace(".eu-west-1", ""),
      method: "PUT",
      data: logo,
      headers: {
        "Content-Type": logo.type,
      },
      withCredentials: false,
    });
    // cloudfront for the s3 bucket
    const url = `https://d2qa3j78m9awdn.cloudfront.net/${res.data.key}`;
    return url;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save the logo. ";
    throw new Error(msg);
  }
};

export const registerRegistrationFinished = async () => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/accounts/registration-finished`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't finish setting up your. ";
    throw new Error(msg);
  }
};

export const getAccountIssues = async () => {
  try {
    const res: AxiosResponse<{
      issues: {
        message: string;
        name: string;
        priority: "warning" | "error" | "info";
      }[];
    }> = await request({
      url: `${apiBase}/v1/accounts/issues`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.issues;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve account issues. ";
    throw new Error(msg);
  }
};

export const rotateAPIKey = async () => {
  try {
    const res = await request({
      url: `${apiBase}/v1/accounts/rotate-api-key`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't rotate this API key. ";
    throw new Error(msg);
  }
};

export const createSubscription = async ({
  planPeople,
  planName,
  token,
}: {
  planName: string;
  planPeople: number;
  token: string;
}) => {
  try {
    const res = await request({
      url: `${apiBase}/v1/payments/subscription`,
      method: "POST",
      withCredentials: true,
      data: {
        planName,
        planPeople,
        token,
      },
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't create the subscription. ";
    throw new Error(msg);
  }
};

export const cancelSubscription = async () => {
  try {
    const res = await request({
      url: `${apiBase}/v1/payments/subscription`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't cancel the subscription. ";
    throw new Error(msg);
  }
};

export const changePlan = async ({
  planPeople,
  planName,
}: {
  planName: string;
  planPeople: number;
}) => {
  try {
    const res = await request({
      url: `${apiBase}/v1/payments/change-plan`,
      method: "POST",
      withCredentials: true,
      data: {
        planName,
        planPeople,
      },
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't change the subscription. ";
    throw new Error(msg);
  }
};

export const addAddon = async (name: string) => {
  try {
    const res = await request({
      url: `${apiBase}/v1/payments/add-addon`,
      method: "POST",
      withCredentials: true,
      data: {
        addonId: name,
      },
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't add the addon. ";
    throw new Error(msg);
  }
};

export const streamAccountOnboarding = async ({
  accountToken,
  streamResponse,
  onDone,
}: {
  accountToken: string;
  streamResponse: (response: string) => void;
  onDone: () => void;
}) => {
  try {
    const appConfig: AxiosResponse<{
      aiOnboardingURL: string;
    }> = await request({
      url: `${apiBase}/v1/app-config`,
      method: "get",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    const aiOnboardingURL = appConfig.data.aiOnboardingURL;

    const response = await fetch(aiOnboardingURL, {
      body: JSON.stringify({
        token: accountToken,
      }),
      method: "POST",
    });
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    if (!response.body) {
      throw new Error("Response body is empty");
    }
    const reader = response.body.getReader();

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        onDone();
        break;
      }
      streamResponse(new TextDecoder("utf-8").decode(value));
    }
  } catch (e) {
    throw new Error(`Error while generating content: ${e.message}`);
  }
};

export const moveToSES = async (accountId: number) => {
  try {
    const res: AxiosResponse<{ success: boolean }> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/ses`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      (e as any)?.response?.data?.error?.message ||
      (e as any).message ||
      "We couldn't move the account to SES.";
    throw new Error(msg);
  }
};

export const moveToSendgrid = async (accountId: number) => {
  try {
    const res: AxiosResponse<{ success: boolean }> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/ses`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      (e as any)?.response?.data?.error?.message ||
      (e as any).message ||
      "We couldn't move the account to Sendgrid.";
    throw new Error(msg);
  }
};

export const resetHubspotSync = async (accountId: number) => {
  try {
    const res: AxiosResponse<{ status: string }> = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/sync/hubspot/reset`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      (e as any)?.response?.data?.error?.message ||
      (e as any).message ||
      "We couldn't reset HubSpot sync.";
    throw new Error(msg);
  }
};

export const getTopSenders = async () => {
  try {
    const res: AxiosResponse = await request({
      url: `${apiBase}/v1/admin/senders`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      (e as any)?.response?.data?.error?.message ||
      (e as any).message ||
      "We couldn't fetch top senders.";
    throw new Error(msg);
  }
};

export const assignIPToAccount = async ({
  accountId,
  ip,
}: {
  accountId: number;
  ip: string;
}) => {
  try {
    const response = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/ips`,
      method: "POST",
      data: { ip },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return response.data;
  } catch (e) {
    console.error("Error assigning IP:", e);
    throw e;
  }
};

export const unassignIPFromAccount = async ({
  accountId,
  targetPool,
}: {
  accountId: number;
  targetPool: string;
}) => {
  try {
    const response = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/ips`,
      method: "DELETE",
      data: { targetPool },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return response.data;
  } catch (e) {
    console.error("Error unassigning IP:", e);
    throw e;
  }
};

export const getAvailableIPs = async (accountId: number) => {
  try {
    const response = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/ips`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return response.data;
  } catch (e) {
    console.error("Error getting available IPs:", e);
    throw e;
  }
};

export const getAvailablePools = async (accountId: number) => {
  try {
    const response = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/ip-pools`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return response.data;
  } catch (e) {
    console.error("Error getting available pools:", e);
    throw e;
  }
};

export const setIpPoolForAccount = async ({
  accountId,
  pool,
}: {
  accountId: number;
  pool: string;
}) => {
  try {
    const response = await request({
      url: `${apiBase}/v1/admin/accounts/${accountId}/ip-pool`,
      method: "POST",
      data: { pool },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return response.data;
  } catch (e) {
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "Failed to set IP pool for account.";
    throw new Error(msg);
  }
};

export interface ModerationMonitoringItem {
  accountId: number;
  email: string;
  name: string;
  averageModerationScore: number;
  emailsCreatedWithScore: number;
}

export interface EmailModerationDetail {
  id: number;
  subject: string;
  createdAt: string;
  moderationTotalScore: number;
  moderationFeedback?: string;
  moderationSuggestions?: string[];
  moderationCategories?: {
    compliance?: { score: number };
    content?: { score: number };
    technical?: { score: number };
  };
  moderationSuggestedLevel?: string;
}

export const getModerationMonitoring = async (
  queryParams?: URLSearchParams
): Promise<{ rows: ModerationMonitoringItem[]; total: number }> => {
  const url = queryParams
    ? `${apiBase}/v1/admin/monitoring/moderation?${queryParams.toString()}`
    : `${apiBase}/v1/admin/monitoring/moderation`;

  const response = await request({
    url,
    method: "GET",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });

  // Handle both the new and old response formats for backward compatibility
  if (Array.isArray(response.data)) {
    // Old format - convert to new format
    return { rows: response.data, total: response.data.length };
  }

  // New format with rows and total
  return response.data;
};

export const getUserEmailModerationDetails = async (
  accountId: number
): Promise<EmailModerationDetail[]> => {
  try {
    const response = await request({
      url: `${apiBase}/v1/admin/monitoring/moderation/${accountId}/emails`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return response.data;
  } catch (e) {
    console.error("Error getting user email moderation details:", e);
    throw e;
  }
};
