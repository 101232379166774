import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-http";
import {
  ICustomObjectCreatable as ICustomObjectCreatableMapper,
  Paths1L82MhObjectsObjectnameIdPatchRequestbodyContentApplicationJsonSchema as Paths1L82MhObjectsObjectnameIdPatchRequestbodyContentApplicationJsonSchemaMapper,
  PickCustomObjectSchemaCreatableExcludeKeyofCustomObjectSchemaCreatableSearchableFields as PickCustomObjectSchemaCreatableExcludeKeyofCustomObjectSchemaCreatableSearchableFieldsMapper,
  PartialOmitCustomObjectSchemaFieldsOrAssociationsOrName as PartialOmitCustomObjectSchemaFieldsOrAssociationsOrNameMapper,
  PartialPickCustomObjectFieldTitleOrTooltipOrDisplayTypeOrIconOrEnumNamesOrEnumOrTypeOrFormat as PartialPickCustomObjectFieldTitleOrTooltipOrDisplayTypeOrIconOrEnumNamesOrEnumOrTypeOrFormatMapper,
  PickCustomObjectSchemaAssociationExcludeKeyofCustomObjectSchemaAssociationId as PickCustomObjectSchemaAssociationExcludeKeyofCustomObjectSchemaAssociationIdMapper,
  PartialIemailContent as PartialIemailContentMapper,
  EmailContentCreate as EmailContentCreateMapper,
  Paths1X3Kl1JEventSubscriptionsPostRequestbodyContentApplicationJsonSchema as Paths1X3Kl1JEventSubscriptionsPostRequestbodyContentApplicationJsonSchemaMapper,
  IPersonField as IPersonFieldMapper,
  PartialOmitIsegmentIdOrAccountIdOrVersion as PartialOmitIsegmentIdOrAccountIdOrVersionMapper,
  Paths3I1ZetTagsPostRequestbodyContentApplicationJsonSchema as Paths3I1ZetTagsPostRequestbodyContentApplicationJsonSchemaMapper,
  Paths1488F72TagsDeleteRequestbodyContentApplicationJsonSchema as Paths1488F72TagsDeleteRequestbodyContentApplicationJsonSchemaMapper,
  Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema as Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchemaMapper,
  Tag as TagMapper,
  AIContentSuggestionGenerateParameters as AIContentSuggestionGenerateParametersMapper,
  PartialBroadcast as PartialBroadcastMapper,
  Broadcast as BroadcastMapper,
  PickIcommunicationCategoryNameOrDescription as PickIcommunicationCategoryNameOrDescriptionMapper,
  PickIcommunicationCategoryNameOrDescriptionOrIsArchived as PickIcommunicationCategoryNameOrDescriptionOrIsArchivedMapper,
  Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema as Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchemaMapper,
  PartialIeventSchema as PartialIeventSchemaMapper,
  PartialIeventSchemaProperty as PartialIeventSchemaPropertyMapper,
  PartialIform as PartialIformMapper,
  PartialIformObject as PartialIformObjectMapper,
  IntegrationDecoration as IntegrationDecorationMapper,
  PartialIsegment as PartialIsegmentMapper,
  ISnippetCreate as ISnippetCreateMapper,
  ISnippetUpdate as ISnippetUpdateMapper,
  Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema as Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchemaMapper,
  Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema as Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchemaMapper,
  PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema as PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchemaMapper,
} from "../models/mappers";

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const formId: OperationURLParameter = {
  parameterPath: "formId",
  mapper: {
    serializedName: "formId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const objectName: OperationURLParameter = {
  parameterPath: "objectName",
  mapper: {
    serializedName: "objectName",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const attributes: OperationQueryParameter = {
  parameterPath: ["options", "attributes"],
  mapper: {
    serializedName: "attributes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const sort: OperationQueryParameter = {
  parameterPath: ["options", "sort"],
  mapper: {
    serializedName: "sort",
    type: {
      name: "String",
    },
  },
};

export const order: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "Enum",
      allowedValues: ["asc", "desc"],
    },
  },
};

export const offset: OperationQueryParameter = {
  parameterPath: ["options", "offset"],
  mapper: {
    serializedName: "offset",
    type: {
      name: "Number",
    },
  },
};

export const limit: OperationQueryParameter = {
  parameterPath: ["options", "limit"],
  mapper: {
    serializedName: "limit",
    type: {
      name: "Number",
    },
  },
};

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: "body",
  mapper: {
    serializedName: "body",
    required: true,
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Composite",
          className: "ICustomObjectCreatable",
        },
      },
    },
  },
};

export const body1: OperationParameter = {
  parameterPath: "body",
  mapper: ICustomObjectCreatableMapper,
};

export const segmentId: OperationURLParameter = {
  parameterPath: "segmentId",
  mapper: {
    serializedName: "segmentId",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const order1: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "Enum",
      allowedValues: ["asc", "desc"],
    },
  },
};

export const query: OperationQueryParameter = {
  parameterPath: "query",
  mapper: {
    serializedName: "query",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const segmentId1: OperationQueryParameter = {
  parameterPath: ["options", "segmentId"],
  mapper: {
    serializedName: "segmentId",
    type: {
      name: "Number",
    },
  },
};

export const id: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const body2: OperationParameter = {
  parameterPath: "body",
  mapper:
    Paths1L82MhObjectsObjectnameIdPatchRequestbodyContentApplicationJsonSchemaMapper,
};

export const externalId: OperationURLParameter = {
  parameterPath: "externalId",
  mapper: {
    serializedName: "externalId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const id1: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "any",
    },
  },
};

export const associationId: OperationURLParameter = {
  parameterPath: "associationId",
  mapper: {
    serializedName: "associationId",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const order2: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "Enum",
      allowedValues: ["asc", "desc"],
    },
  },
};

export const targetObjectName: OperationURLParameter = {
  parameterPath: "targetObjectName",
  mapper: {
    serializedName: "targetObjectName",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const targetId: OperationURLParameter = {
  parameterPath: "targetId",
  mapper: {
    serializedName: "targetId",
    required: true,
    type: {
      name: "any",
    },
  },
};

export const body3: OperationParameter = {
  parameterPath: "body",
  mapper:
    PickCustomObjectSchemaCreatableExcludeKeyofCustomObjectSchemaCreatableSearchableFieldsMapper,
};

export const body4: OperationParameter = {
  parameterPath: "body",
  mapper: PartialOmitCustomObjectSchemaFieldsOrAssociationsOrNameMapper,
};

export const body5: OperationParameter = {
  parameterPath: "body",
  mapper: {
    serializedName: "body",
    required: true,
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Composite",
          className: "IPersonField",
        },
      },
    },
  },
};

export const body6: OperationParameter = {
  parameterPath: "body",
  mapper:
    PartialPickCustomObjectFieldTitleOrTooltipOrDisplayTypeOrIconOrEnumNamesOrEnumOrTypeOrFormatMapper,
};

export const fieldName: OperationURLParameter = {
  parameterPath: "fieldName",
  mapper: {
    serializedName: "fieldName",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body7: OperationParameter = {
  parameterPath: "body",
  mapper:
    PickCustomObjectSchemaAssociationExcludeKeyofCustomObjectSchemaAssociationIdMapper,
};

export const body8: OperationParameter = {
  parameterPath: "body",
  mapper: PartialIemailContentMapper,
};

export const body9: OperationParameter = {
  parameterPath: "body",
  mapper: EmailContentCreateMapper,
};

export const body10: OperationParameter = {
  parameterPath: "body",
  mapper:
    Paths1X3Kl1JEventSubscriptionsPostRequestbodyContentApplicationJsonSchemaMapper,
};

export const body11: OperationParameter = {
  parameterPath: "body",
  mapper: IPersonFieldMapper,
};

export const body12: OperationParameter = {
  parameterPath: "body",
  mapper: PartialOmitIsegmentIdOrAccountIdOrVersionMapper,
};

export const limit1: OperationQueryParameter = {
  parameterPath: ["options", "limit"],
  mapper: {
    defaultValue: 100,
    serializedName: "limit",
    type: {
      name: "Number",
    },
  },
};

export const offset1: OperationQueryParameter = {
  parameterPath: ["options", "offset"],
  mapper: {
    defaultValue: 0,
    serializedName: "offset",
    type: {
      name: "Number",
    },
  },
};

export const order3: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "Enum",
      allowedValues: ["asc", "desc"],
    },
  },
};

export const ignoreAnonymous: OperationQueryParameter = {
  parameterPath: ["options", "ignoreAnonymous"],
  mapper: {
    serializedName: "ignoreAnonymous",
    type: {
      name: "Boolean",
    },
  },
};

export const body13: OperationParameter = {
  parameterPath: "body",
  mapper: Paths3I1ZetTagsPostRequestbodyContentApplicationJsonSchemaMapper,
};

export const body14: OperationParameter = {
  parameterPath: "body",
  mapper: Paths1488F72TagsDeleteRequestbodyContentApplicationJsonSchemaMapper,
};

export const body15: OperationParameter = {
  parameterPath: "body",
  mapper:
    Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchemaMapper,
};

export const body16: OperationParameter = {
  parameterPath: "body",
  mapper: TagMapper,
};

export const tag: OperationURLParameter = {
  parameterPath: "tag",
  mapper: {
    serializedName: "tag",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const order4: OperationQueryParameter = {
  parameterPath: ["options", "order"],
  mapper: {
    serializedName: "order",
    type: {
      name: "Enum",
      allowedValues: ["asc", "desc"],
    },
  },
};

export const email: OperationQueryParameter = {
  parameterPath: ["options", "email"],
  mapper: {
    serializedName: "email",
    type: {
      name: "String",
    },
  },
};

export const userId: OperationQueryParameter = {
  parameterPath: ["options", "userId"],
  mapper: {
    serializedName: "userId",
    type: {
      name: "String",
    },
  },
};

export const id2: OperationQueryParameter = {
  parameterPath: ["options", "id"],
  mapper: {
    serializedName: "id",
    type: {
      name: "String",
    },
  },
};

export const body17: OperationParameter = {
  parameterPath: "body",
  mapper: AIContentSuggestionGenerateParametersMapper,
};

export const id3: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body18: OperationParameter = {
  parameterPath: "body",
  mapper: PartialBroadcastMapper,
};

export const body19: OperationParameter = {
  parameterPath: "body",
  mapper: BroadcastMapper,
};

export const body20: OperationParameter = {
  parameterPath: "body",
  mapper: PickIcommunicationCategoryNameOrDescriptionMapper,
};

export const body21: OperationParameter = {
  parameterPath: "body",
  mapper: PickIcommunicationCategoryNameOrDescriptionOrIsArchivedMapper,
};

export const token: OperationQueryParameter = {
  parameterPath: "token",
  mapper: {
    serializedName: "token",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body22: OperationParameter = {
  parameterPath: "body",
  mapper: {
    serializedName: "body",
    required: true,
    type: {
      name: "Dictionary",
      value: {
        type: {
          name: "Enum",
          allowedValues: ["optIn", "optOut", "unspecified"],
        },
      },
    },
  },
};

export const mode: OperationURLParameter = {
  parameterPath: "mode",
  mapper: {
    serializedName: "mode",
    required: true,
    type: {
      name: "Enum",
      allowedValues: ["manage", "all", "category"],
    },
  },
};

export const body23: OperationParameter = {
  parameterPath: "body",
  mapper:
    Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchemaMapper,
};

export const email1: OperationURLParameter = {
  parameterPath: "email",
  mapper: {
    serializedName: "email",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const ids: OperationQueryParameter = {
  parameterPath: "ids",
  mapper: {
    serializedName: "ids",
    required: true,
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const eventSchemaId: OperationQueryParameter = {
  parameterPath: "eventSchemaId",
  mapper: {
    serializedName: "eventSchemaId",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const body24: OperationParameter = {
  parameterPath: "body",
  mapper: PartialIeventSchemaMapper,
};

export const body25: OperationParameter = {
  parameterPath: "body",
  mapper: PartialIeventSchemaPropertyMapper,
};

export const eventSchemaId1: OperationURLParameter = {
  parameterPath: "eventSchemaId",
  mapper: {
    serializedName: "eventSchemaId",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const body26: OperationParameter = {
  parameterPath: "body",
  mapper: PartialIformMapper,
};

export const body27: OperationParameter = {
  parameterPath: "body",
  mapper: {
    serializedName: "body",
    required: true,
    type: {
      name: "any",
    },
  },
};

export const body28: OperationParameter = {
  parameterPath: "body",
  mapper: PartialIformObjectMapper,
};

export const body29: OperationParameter = {
  parameterPath: "body",
  mapper: IntegrationDecorationMapper,
};

export const decorationId: OperationURLParameter = {
  parameterPath: "decorationId",
  mapper: {
    serializedName: "decorationId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body30: OperationParameter = {
  parameterPath: "body",
  mapper: PartialIsegmentMapper,
};

export const body31: OperationParameter = {
  parameterPath: "body",
  mapper: ISnippetCreateMapper,
};

export const body32: OperationParameter = {
  parameterPath: "body",
  mapper: ISnippetUpdateMapper,
};

export const serviceId: OperationURLParameter = {
  parameterPath: "serviceId",
  mapper: {
    serializedName: "serviceId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body33: OperationParameter = {
  parameterPath: "body",
  mapper:
    Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchemaMapper,
};

export const body34: OperationParameter = {
  parameterPath: "body",
  mapper:
    Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchemaMapper,
};

export const body35: OperationParameter = {
  parameterPath: "body",
  mapper:
    PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchemaMapper,
};
