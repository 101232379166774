import { observable, action, runInAction } from "mobx";
import { DomainStore } from "./domainStore";
import {
  ISnippet,
  ISnippetCreate,
  ISnippetUpdate,
} from "encharge-domain/lib/definitions/Snippet";
import enchargeAPI, { parseAPIErrorMessage } from "./persistence/enchargeAPI";

export class SnippetStore {
  @observable snippets: ISnippet[] = [];
  @observable snippetsLoaded: boolean = false;
  @observable loading: boolean = false;
  @observable error: string | null = null;

  rootStore: DomainStore;

  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setError(error: string | null) {
    this.error = error;
  }

  @action
  setSnippets(snippets: ISnippet[]) {
    this.snippets = snippets;
  }

  async fetchSnippets() {
    this.setLoading(true);
    this.setError(null);
    try {
      const response = await enchargeAPI.getSnippets();
      runInAction(() => {
        this.snippets = response.snippets;
        this.snippetsLoaded = true;
      });
    } catch (error) {
      runInAction(() => {
        this.setError(
          parseAPIErrorMessage(error) || "Failed to fetch snippets"
        );
      });
    } finally {
      runInAction(() => {
        this.setLoading(false);
      });
    }
  }

  async createSnippet(data: ISnippetCreate) {
    this.setLoading(true);
    this.setError(null);
    try {
      const response = await enchargeAPI.createSnippet(data);
      runInAction(() => {
        this.snippets.push(response.item);
      });
      return response.item;
    } catch (error) {
      runInAction(() => {
        this.setError(
          parseAPIErrorMessage(error) || "Failed to create snippet"
        );
      });
      throw error;
    } finally {
      runInAction(() => {
        this.setLoading(false);
      });
    }
  }

  async updateSnippet(data: ISnippetUpdate) {
    this.setLoading(true);
    this.setError(null);
    try {
      const response = await enchargeAPI.updateSnippet(String(data.id), data);
      runInAction(() => {
        const index = this.snippets.findIndex((s) => s.id === data.id);
        if (index !== -1) {
          this.snippets[index] = response.item;
        }
      });
      return response.item;
    } catch (error) {
      runInAction(() => {
        this.setError(
          parseAPIErrorMessage(error) || "Failed to update snippet"
        );
      });
      throw error;
    } finally {
      runInAction(() => {
        this.setLoading(false);
      });
    }
  }

  async deleteSnippet(id: number) {
    this.setLoading(true);
    this.setError(null);
    try {
      await enchargeAPI.deleteSnippet(String(id));
      runInAction(() => {
        this.snippets = this.snippets.filter((s) => s.id !== id);
      });
    } catch (error) {
      runInAction(() => {
        this.setError(
          parseAPIErrorMessage(error) || "Failed to delete snippet"
        );
      });
      throw error;
    } finally {
      runInAction(() => {
        this.setLoading(false);
      });
    }
  }

  async getSnippet(id: number) {
    try {
      const response = await enchargeAPI.getSnippet(id);
      return response.snippet;
    } catch (error) {
      this.setError(parseAPIErrorMessage(error) || "Failed to fetch snippet");
      throw error;
    }
  }

  @action
  async archiveSnippet(id: number) {
    try {
      await enchargeAPI.deleteSnippet(String(id));
      // Remove the snippet from the list after archiving
      runInAction(() => {
        this.snippets = this.snippets.filter((snippet) => snippet.id !== id);
      });
      return true;
    } catch (error) {
      this.setError(parseAPIErrorMessage(error) || "Failed to archive snippet");
      return false;
    }
  }
}

export default SnippetStore;
