import { useScript } from "hooks/useScript";

const ProactiveReachoutChatInner = () => {
  const w = window as any;
  w.$crisp = [];
  w.CRISP_WEBSITE_ID = "8e3f2c15-500f-44c9-8e6c-7ab0f6361b11";
  useScript("https://client.crisp.chat/l.js");
  return null;
};

export default ProactiveReachoutChatInner;
