import { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { observer } from "mobx-react";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { useStores } from "store/useStore";
import Loading from "components/Loading";
import ButtonLoader from "components/ButtonLoader";
import { loadChargebee } from "components/Billing/loadChargebee";
import {
  activePlans,
  appSumoPlans,
  creativeJourneysPlans,
} from "encharge-domain/lib/values/plans";
import { numberWithCommas } from "domain/helpers/metrics";
import { FrontendAccount } from "store/accountStore";
import _ from "lodash";

const displayPlanWithLessThanPeople = 50000;
interface Props {
  account: FrontendAccount;
  email: string;
  planName?: string;
  planPeriod?: string;
  planPeopleUpperLimit?: number;
  onCancel: () => void;
  confirmButtonText?: string;
}

const ChargebeeCheckout = observer((props: Props) => {
  const { segmentStore } = useStores();
  let confirmButtonText = props.confirmButtonText || "Upgrade to this plan";
  const peopleUpperLimit = props.planPeopleUpperLimit || 0;

  // checks if script is loaded
  const [chargebeeLoaded, setChargebeeLoaded] = useState(
    Boolean(document.getElementById("chargebee-script"))
  );
  useEffect(() => {
    // allow us to checkout
    const onload = () => setChargebeeLoaded(true);
    loadChargebee(onload);
  });

  const isAppSumo = Boolean(props.account?.payment?.appsumo);
  const currentAccountPeople = props.account?.peopleCount || 0;

  let plans = isAppSumo ? appSumoPlans : activePlans;
  let filteredPlans = plans
    .filter((plan) => {
      if (!plan.includeAccountIds) {
        return true;
      }
      if (
        plan.includeAccountIds?.includes(props.account.accountId.toString())
      ) {
        return true;
      }
      return false;
    })
    .filter((plan) => {
      if (!plan.excludeAccountIds) {
        return true;
      }
      if (
        plan.excludeAccountIds?.includes(props.account.accountId.toString())
      ) {
        return false;
      }
      return true;
    });
  const firstPromoterPromoterId = (
    (window as any)?.$FPROM?.data?.url_ref_id ||
    (window as any)?.$FPROM?.data?.ref_id
  )?.replace?.("_r_", "");
  if (
    firstPromoterPromoterId === "creativejourneys" ||
    props.account.flags?.showCreativeJourneysPlans
  ) {
    filteredPlans = [
      // use creative journeys plans for growth
      ...creativeJourneysPlans,
      // add all other plans except growth
      ..._.filter(filteredPlans, (plan) => plan.tier !== "growth"),
    ];
  }

  const monthlyGrowthPlans: JSX.Element[] = [];
  const monthlyPremiumPlans: JSX.Element[] = [];
  const yearlyGrowthPlans: JSX.Element[] = [];
  const yearlyPremiumPlans: JSX.Element[] = [];
  let firstPremiumReached = false;
  // normal plans
  filteredPlans.forEach((plan) => {
    if (plan.peopleUpperLimit > displayPlanWithLessThanPeople) {
      return;
    }
    if (!isAppSumo && plan.peopleUpperLimit < currentAccountPeople) {
      return;
    }
    if (peopleUpperLimit < plan.peopleUpperLimit) {
      let price: number | string =
        plan.period === "month" ? plan.price : Math.ceil(plan.price / 12);
      let name = "Monthly Growth";
      if (plan.period === "year") {
        name = "Annual Growth";
      }
      if (plan.tier === "premium") {
        name = name.replace("Growth", "Premium");
      }
      const planOption = (
        <option
          key={`${plan.name}:${plan.peopleUpperLimit}`}
          value={`${plan.name}:${plan.peopleUpperLimit}`}
          selected={!firstPremiumReached && plan.tier === "premium"}
        >
          {name}: {numberWithCommas(plan.peopleLowerLimit)} -{" "}
          {numberWithCommas(plan.peopleUpperLimit)} People: ${price}{" "}
          {
            // display annual prices per month too
          }
          per month {plan.period === "year" && "(billed yearly) - 20% OFF"}
          {plan.name.includes("appsumo")
            ? "- 50% Exclusive AppSumo Discount!"
            : ""}
        </option>
      );
      if (plan.tier === "premium" && !firstPremiumReached) {
        firstPremiumReached = true;
      }
      if (plan.period === "month") {
        if (plan.tier === "growth") {
          monthlyGrowthPlans.push(planOption);
        } else {
          monthlyPremiumPlans.push(planOption);
        }
      } else {
        if (plan.tier === "growth") {
          yearlyGrowthPlans.push(planOption);
        } else {
          yearlyPremiumPlans.push(planOption);
        }
      }
    }
  });

  const contactUs = "contact-us";

  // set first plan as default
  const [selectedPlan, setSelectedPlan] = useState<string>(
    `${monthlyPremiumPlans?.[0]?.key || contactUs}`
  );

  const contactUsOption = (
    <option key={contactUs} value={contactUs}>
      Have more than 50,000 people? Contact us for a custom quote.
    </option>
  );
  monthlyGrowthPlans.push(contactUsOption);
  monthlyPremiumPlans.push(contactUsOption);
  yearlyGrowthPlans.push(contactUsOption);
  yearlyPremiumPlans.push(contactUsOption);
  if (selectedPlan === contactUs) {
    confirmButtonText = "Contact Us";
  } else {
    confirmButtonText = selectedPlan.includes("growth")
      ? "Upgrade to Growth"
      : "Upgrade to Premium";
  }

  const missedCriticalFeatures = [];
  const missedPremiumFeatures = [];
  if (selectedPlan.includes("growth")) {
    if (segmentStore.checkIfHasEventBasedSegments()) {
      missedCriticalFeatures.push("Event-based segmentation");
    } else {
      missedPremiumFeatures.push("Event-based segmentation");
    }
    if (props.account.activeServices.includes("hubspot")) {
      missedCriticalFeatures.push("HubSpot.com integration");
    } else {
      missedPremiumFeatures.push("HubSpot.com integration");
    }
    if (props.account.activeServices.includes("salesforce")) {
      missedCriticalFeatures.push("Salesforce integration");
    } else {
      missedPremiumFeatures.push("Salesforce integration");
    }
    if (props.account.activeServices.includes("segment")) {
      missedCriticalFeatures.push("Segment.com integration");
    } else {
      missedPremiumFeatures.push("Segment.com integration");
    }

    missedPremiumFeatures.push("Transactional emails");
    missedPremiumFeatures.push("Premium support and more");
  }

  return (
    <Modal
      isOpen={true}
      toggle={() => props.onCancel()}
      zIndex={2100}
      size="lg"
      className="convert-popup"
    >
      <ModalBody className="p-0">
        <div
          style={{
            width: "100%",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/upgrade-popup.png`}
            alt={confirmButtonText}
          />
          <div className="popup-title">{confirmButtonText}</div>
        </div>
        <div className="popup-content">
          <div>Choose plan:</div>
          <form id="preorder-form">
            <select
              className="form-control mt-3 mb-3"
              id="plans-people-picker"
              required={true}
              onChange={(e) => {
                setSelectedPlan(e.target.value);
              }}
            >
              <optgroup label="Monthly Growth">{monthlyGrowthPlans}</optgroup>
              <optgroup label="Monthly Premium">{monthlyPremiumPlans}</optgroup>
              {yearlyGrowthPlans?.length && (
                <optgroup label="Annual Growth - 20% OFF">
                  {yearlyGrowthPlans}
                </optgroup>
              )}
              <optgroup label="Annual Premium - 20% OFF">
                {yearlyPremiumPlans}
              </optgroup>
            </select>
            <div className="benefits-description my-3">
              {selectedPlan.includes("premium") ? (
                <>
                  <div className="mb-2">With Premium you get:</div>
                  <ul>
                    <li>Segment.com integration</li>
                    <li>HubSpot.com integration</li>
                    <li>Salesforce integration</li>
                    <li>Transactional emails</li>
                    <li>Premium support and more</li>
                  </ul>
                </>
              ) : null}
              {selectedPlan.includes(contactUs) ? (
                <>
                  <div className="mb-2">
                    Click the button below to contact us for a custom quote.
                  </div>
                </>
              ) : null}
              {selectedPlan.includes("growth") ? (
                <>
                  {missedCriticalFeatures?.length ? (
                    <div className="text-danger">
                      <div className="my-2">
                        Important: By upgrading to Growth you will lose access
                        to these Premium features currently in use:
                      </div>
                      <ul>
                        {missedCriticalFeatures.map((feature) => (
                          <li className="font-weight-normal" key={feature}>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  {missedPremiumFeatures?.length ? (
                    <>
                      <div className="my-2">
                        {missedCriticalFeatures?.length
                          ? "Also, you will lose access to these powerful features:"
                          : "By upgrading to Growth you will lose access to these Premium features:"}
                      </div>
                      <ul>
                        {missedPremiumFeatures.map((feature) => (
                          <li key={feature}>{feature}</li>
                        ))}
                      </ul>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className="w-100 text-center">
              <div>
                {!chargebeeLoaded ? (
                  // display loading if chargebee is not loaded
                  <Loading />
                ) : (
                  <>
                    {selectedPlan.includes("growth") ? (
                      <ButtonLoader
                        color="secondary"
                        className="mt-3 btn-custom-outline-dark btn-lg mr-3"
                        type="button"
                        size={"lg"}
                        onClick={() => {
                          if (!chargebeeLoaded) return;

                          const upgradePlan = selectedPlan.split(":")[0];
                          const upgradePeople = Number(
                            selectedPlan.split(":")[1]
                          );
                          openChargebeeCheckout({
                            accountId:
                              props.account?.mainAccountId ||
                              props.account!.accountId,
                            email: props.account!.email,
                            plan: upgradePlan,
                            people: upgradePeople,
                          });
                        }}
                      >
                        {"Continue with Growth"}
                      </ButtonLoader>
                    ) : null}
                    <ButtonLoader
                      color="primary"
                      className="mt-3"
                      type="button"
                      size={"lg"}
                      onClick={() => {
                        if (selectedPlan === contactUs) {
                          const mailto =
                            "mailto:support@encharge.io?subject=Custom%20Plan%20Quote";
                          window.open(mailto);
                          return;
                        }
                        if (!chargebeeLoaded) return;

                        const upgradePlan = selectedPlan
                          .split(":")[0]
                          .replace("growth", "premium");
                        const upgradePeople = Number(
                          selectedPlan.split(":")[1]
                        );
                        openChargebeeCheckout({
                          accountId:
                            props.account?.mainAccountId ||
                            props.account!.accountId,
                          email: props.account!.email,
                          plan: upgradePlan,
                          people: upgradePeople,
                        });
                      }}
                    >
                      {confirmButtonText.replace("Growth", "Premium")}
                    </ButtonLoader>
                  </>
                )}
              </div>

              <div>
                <Button
                  color="link"
                  className="mt-3"
                  onClick={() => props.onCancel()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
});

export default ChargebeeCheckout;

export const openChargebeeCheckout = ({
  accountId,
  email,
  plan,
  people,
}: {
  accountId: IAccount["id"];
  email?: string;
  plan?: string;
  people?: number;
}) => {
  console.log("openChargebeeCheckout", plan, people);
  const chargebeeInstance = (window as any).Chargebee.getInstance();
  const cart = chargebeeInstance.getCart();
  const product = chargebeeInstance.initializeProduct(plan, people);
  const customerData = {
    email,
    id: accountId,
    cf_accountid: accountId,
  } as Dictionary<any>;
  // Get the ID to track affiliate signups
  const firstPromoterId = (window as any)?.$FPROM?.data?.tid;
  if (firstPromoterId) {
    customerData.til = firstPromoterId;
  }

  cart.setCustomer(customerData);
  cart.replaceProduct(product);
  cart.proceedToCheckout();
};
