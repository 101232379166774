import { useState } from "react";
import AppNavDropdown from "./AppNavDropdown";
import { ReactComponent as PeopleIcon } from "../../assets/img/nav/people.svg";
import { ReactComponent as CompaniesIcon } from "../../assets/img/nav/companies.svg";
import { ReactComponent as CustomObjectIcon } from "../../assets/img/nav/custom-objects.svg";
import { CustomObjectSchema } from "encharge-domain/definitions/CustomObjectSchema";
import _ from "lodash";
import {
  companyObjectType,
  endUsersObjectType,
} from "encharge-domain/lib/helpers/sync_engine_helper";
import { useStores } from "store/useStore";

interface Props {
  schemas: CustomObjectSchema[];
}
/**
 * Dropdown for app navigation
 */
const AppNavObjects = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { accountStore } = useStores();
  const schemaOptions = _.map(
    _.filter(props.schemas, (schema) => {
      if (
        schema.name === companyObjectType &&
        accountStore.account?.is2025Appsumo
      ) {
        return false;
      }
      return schema.name !== endUsersObjectType;
    }),
    (schema) => {
      return {
        contents: (
          <>
            {schema.name === companyObjectType ? (
              <CompaniesIcon className="nav-icon" />
            ) : (
              <CustomObjectIcon className="nav-icon" />
            )}
            {schema.displayNamePlural}
          </>
        ),
        to: `/objects/${schema.name}`,
      };
    }
  );

  return (
    <div
      className="sidebar-nav-item cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span>
        <span className="main-bar-nav-item">
          People
          <div className="ml-2 caret" />
        </span>
        <AppNavDropdown
          className="mt-0 main-bar-nav-item-manage"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          placement={"bottom-start"}
          options={[
            {
              contents: (
                <>
                  <PeopleIcon className="nav-icon" />
                  People
                </>
              ),
              to: "/segments",
            },
            ...schemaOptions,
            // {
            //   contents: (
            //     <>
            //       <CompaniesIcon className="nav-icon" />
            //       Companies
            //     </>
            //   ),
            //   to: "/objects/company",
            // },
          ]}
        />
      </span>
    </div>
  );
};
export default AppNavObjects;
